import { Situacao } from '../enums/Situacao';
import { SimNao } from '../enums/SimNao';

export class BaseEntity {

    id?: number;
    tenant?: number;
    dataHoraInclusao?: Date;
    horaInclusao?: Date;
    dataModificacao?: Date;
    usuarioInclusao?: number;
    usuarioModificacao?: number;
    situacao?: Situacao;
    seIgnorarTenant?: SimNao;
}
