import { NgModule, LOCALE_ID } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { registerLocaleData } from '@angular/common';

import { LoginComponent } from './login/login.component';
import { AcessarComponent } from './login/acessar/acessar.component';
import { EsqueciMinhaSenhaComponent } from './login/esqueci-minha-senha/esqueci-minha-senha.component';
import { SistemaComponent } from './sistema/sistema.component';
import { AuthenicationGuard } from './core/guard/authentication.guard';
import { HomeComponent } from './sistema/home/home.component';
import ptBr from '@angular/common/locales/pt';
import { ProdutosComponent } from './sistema/produtos/produtos.component';
import { UsuariosComponent } from './sistema/usuarios/usuarios.component';
import { UsuarioComponent } from './sistema/usuario/usuario.component';
import { PedidosComponent } from './sistema/pedidos/pedidos.component';
import { ConfiguracoesComponent } from './sistema/configuracoes/configuracoes.component';
import { StatusComponent } from './sistema/status/status.component';
import { ImportacoesComponent } from './sistema/importacoes/importacoes.component';
import { EstoquesComponent } from './sistema/estoques/estoques.component';
import { ClientesComponent } from './sistema/clientes/clientes.component';

registerLocaleData(ptBr);

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
        children: [
            {
                path: '',
                component: AcessarComponent
            },
            {
                path: 'esqueci-minha-senha',
                component: EsqueciMinhaSenhaComponent
            }
        ]
    },
    {
        path: '',
        component: SistemaComponent,
        canActivate: [
         	AuthenicationGuard
        ],
        children: [
            {
                path: '',
                component: HomeComponent
            },
            {
                path: 'produtos',
                component: ProdutosComponent
            },                     
            {
                path: 'usuarios',
                component: UsuariosComponent
            },
            {
                path: 'usuario',
                component: UsuarioComponent
            },
            {
                path: 'usuario/:id',
                component: UsuarioComponent
            },
            {
                path: 'pedidos',
                component: PedidosComponent
            },
            {
                path: 'configuracoes',
                component: ConfiguracoesComponent
            },
            {
                path: 'status',
                component: StatusComponent
            },
            {
                path: 'importacoes',
                component: ImportacoesComponent
            },
            {
                path: 'movimentacoes',
                component: EstoquesComponent
            },
            {
                path: 'clientes',
                component: ClientesComponent
            }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'top'
    })],
    exports: [RouterModule],
    providers: [{ provide: LOCALE_ID, useValue: 'pt' }],
})
export class AppRoutingModule { }
