import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ResumoDto } from 'src/app/shared/interfaces/dto/ResumoDto';
import { environment } from 'src/environments/environment';
import { Paginacao } from 'src/app/shared/interfaces/Paginacao';
import { ProdutoDto } from 'src/app/shared/interfaces/dto/ProdutoDto';
import { UsuarioService } from '../services/usuario.service';


@Component({
    templateUrl: './resumo.component.html',
    styleUrls: ['./resumo.styl'],
    providers: [
        UsuarioService
    ]
})
export class ResumoComponent implements OnInit, OnDestroy {

    resumoDto: ResumoDto = {};
    resumoDto$: Subscription;
    logs$: Subscription;

    constructor(
        private usuarioService: UsuarioService,
        private http: HttpClient
    ) { }

    ngOnInit() {
        this.buscarResumo();
    }

    ngOnDestroy() {
        // this.resumoDto$.unsubscribe();
        // this.logs$.unsubscribe();
    }

    private buscarResumo() {
        this.http.get(environment.URL_BASE + "WS/Exportar/Resumo").subscribe((response: ResumoDto) => {
            this.resumoDto = response;
        })
    }

}
