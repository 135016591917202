import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Paginacao } from "src/app/shared/interfaces/Paginacao";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { Pedido } from "src/app/shared/interfaces/Pedido";
import { FiltroBarraPesquisa } from "src/app/shared/interfaces/FiltroBarraPesquisa";

@Injectable()
export class PedidoService {
  constructor(private http: HttpClient) {}

  buscarPedidos(filtro: FiltroBarraPesquisa, pagina: number): Observable<Paginacao<Pedido>> {
    return this.http.post<Paginacao<Pedido>>(environment.URL_BASE + "WS/Pedido/Filtrar?page=" + pagina, filtro);
  }

  reintegrarPedido(id: string) {
    return this.http.put<string>(environment.URL_BASE + "WS/Pedido/" + id, {});
  }

  removerPedido(id: string) {
    return this.http.delete<string>(environment.URL_BASE + "WS/Pedido/" + id, {});
  }  

  salvarIbge(id: string, ibge: string) {
    return this.http.put<string>(environment.URL_BASE + "WS/Pedido/" + id + "/" + ibge, {});
  }
}
