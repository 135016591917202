import { Component, OnInit } from '@angular/core';
import { Column } from 'src/app/shared/interfaces/Column';
import { Subscription } from 'rxjs';
import { Paginacao } from 'src/app/shared/interfaces/Paginacao';
import { formatarEndereco } from 'src/app/shared/util/formatters';
import { Router } from '@angular/router';
import { FiltroBarraPesquisa } from 'src/app/shared/interfaces/FiltroBarraPesquisa';
import { Usuario } from 'src/app/shared/interfaces/Usuario';
import { UsuarioService } from '../services/usuario.service';

@Component({
    templateUrl: './usuarios.component.html',
    styleUrls: ['./usuarios.styl'],
    providers: [
        UsuarioService
    ]
})
export class UsuariosComponent implements OnInit {

    colunas: Column[];
    usuariosPagina: Paginacao<Usuario> = {};
    usuarios$: Subscription;
    filtro: FiltroBarraPesquisa = {};

    constructor(
        private usuariosService: UsuarioService,
        private router: Router
    ) { }

    ngOnInit() {
        this.colunas = [
            {
                field: 'nome',
                header: 'USUÁRIO'
            },
            {
                field: 'email',
                header: 'E-MAIL',
            },
            {
                field: 'celular',
                header: 'TELEFONE',
            },
        ];
        this.buscarusuarios(this.filtro, 0);
    }

    loadDataOnScroll(event) {
        this.buscarusuarios(this.filtro, event.first / event.rows);
    }

    editarusuario(usuario: Usuario) {
        this.router.navigate(['usuario', usuario.id]);
    }

    cadastrarNovousuario() {
        this.router.navigate(['usuario']);
    }

    public buscarusuarios = (filtro: FiltroBarraPesquisa, pagina: number) => {
        this.usuariosService.buscarUsuarios(filtro, pagina).subscribe(
            response => {
                this.usuariosPagina = response;
            }
        );
    }

}
