import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginModule } from './login/login.module';
import { TokenInterceptor } from './core/token/token.interceptor';
import { TokenIsInvalidInterceptor } from './core/token/tokenIsInvalid.interceptor';
import { SistemaModule } from './sistema/sistema.module';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { DropdownModule } from 'primeng/dropdown';
import { CurrencyMaskModule } from "ng2-currency-mask";
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;



registerLocaleData(localePt, 'pt')

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        BrowserAnimationsModule,
        LoginModule,
        SistemaModule,
        NgxMaskModule.forRoot(options),
        CurrencyMaskModule,
        DropdownModule

    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TokenIsInvalidInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: "pt-BR" },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
