import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TokenService } from '../core/token/token.service';
import { Login } from '../shared/interfaces/Login';
import { environment } from 'src/environments/environment';
import { UsuarioService } from '../sistema/services/usuario.service';
import { Usuario } from '../shared/interfaces/Usuario';

@Injectable()
export class LoginService {

    URL: string;

    constructor(
        private http: HttpClient,
        private tokenService: TokenService,
        private usuarioService: UsuarioService
    ) {
        this.URL = environment.URL_BASE;
    }
    
    extrairUsuario(data: any) : Usuario {
        var usuario : Usuario = {};

        usuario.id = data.id;
        usuario.nome = data.nome;
        usuario.email = data.email;

        return usuario;
    }


    autenticar(usuario: Login): Observable<any> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/x-www-form-urlencoded',
                'Authorization': 'Basic TkFWRUdBRE9SOk40VjNHNEQwUg=='
            })
        };

        const payload = new HttpParams()
        .set('username', usuario.usuario)
        .set('password', usuario.senha)
        .set('client', 'NAVEGADOR')
        .set('grant_type', 'password');

        return this.http.post(`${this.URL}oauth/token`, payload, httpOptions )
            .pipe(
                tap(
                    res => {
                        const data = res;
                        this.tokenService.setToken(data.access_token);
                        this.usuarioService.setUsuarioLogado(this.extrairUsuario(data));
                    }
                )
            )
        ;
    }


    recuperarSenha(email: string): Observable<any> {
        return this.http.post(`${this.URL}WS/Usuario/Open/RecuperarSenha`, { email }, { observe: 'response'} );
    }


      // Manipulação de erros
    handleError(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Erro ocorreu no lado do client
            errorMessage = error.error.message;
        } else {
            // Erro ocorreu no lado do servidor
            errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    };
}