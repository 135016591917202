import { Component, OnInit } from "@angular/core";
import { Column } from "src/app/shared/interfaces/Column";
import { Subscription } from "rxjs";
import { Paginacao } from "src/app/shared/interfaces/Paginacao";
import { Produto } from "src/app/shared/interfaces/Produto";
import { ProdutoService } from "../services/produto.service";
import { Router } from "@angular/router";
import { FiltroBarraPesquisa } from "src/app/shared/interfaces/FiltroBarraPesquisa";
import { SelectItem } from "primeng/api";

@Component({
  templateUrl: "./produtos.component.html",
  styleUrls: ["./produtos.styl"],
  providers: [ProdutoService],
})
export class ProdutosComponent implements OnInit {
  colunas: Column[];
  produtosPagina: Paginacao<Produto> = {};
  produtos$: Subscription;
  filtro: FiltroBarraPesquisa = {};
  filtrarErro: number = 0;
  comErro: SelectItem[];

  constructor(private produtosService: ProdutoService, private router: Router) {
    this.comErro = [
      { label: "Sim", value: 1 },
      { label: "Não", value: 0 },
    ];
  }

  ngOnInit() {
    this.colunas = [
      {
        field: "produto",
        header: "PRODUTO",
        format: this.formatarCodigoProduto,
      },
      {
        field: "status",
        header: "TENTATIVAS DE INTEGRAR",
        format: this.formatarStatus,
      },
      {
        field: "statusMsg",
        header: "MENSAGEM",
        format: this.formatarMensagem,
      },
    ];
    this.buscarProdutos(this.filtro, 0);
  }

  private formatarCodigoProduto(produto: Produto): string {
    return produto.data.idOrigem;
  }

  private formatarStatus(produto: Produto): string {
    return produto.status;
  }

  private formatarMensagem(produto: Produto): string {
    if (produto.statusMsg != null) {
      if (produto.statusMsg.substr(0, 5) === "java.") {
        return produto.statusMsg.split(":", 2)[1].split("at")[0];
      } else {
        if (produto.statusMsg.substr(0, 3) === "br.") {
          return produto.statusMsg.split("DemacodeException:", 2)[1].split("\n")[0];
        } else {
          return produto.statusMsg;
        }
      }
    }

    return null;
  }

  loadDataOnScroll(event) {
    this.buscarProdutos(this.filtro, event.first / event.rows);
  }

  reintegrarProduto(produto: Produto) {
    this.produtosService.reintegrarProduto(produto.id).subscribe((response) => {
      this.ngOnInit();
    });
    setTimeout(() => {
      this.ngOnInit();
    }, 1000);
  }

  buscar() {
    this.buscarProdutos(this.filtro, 0);
  }

  public buscarProdutos = (filtro: FiltroBarraPesquisa, pagina: number) => {
    this.produtosService.buscarProdutos(filtro, pagina).subscribe((response) => {
      this.produtosPagina = response;
    });
  };
}
