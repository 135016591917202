import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Paginacao } from 'src/app/shared/interfaces/Paginacao';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { FiltroBarraPesquisa } from 'src/app/shared/interfaces/FiltroBarraPesquisa';
import { Estoque } from 'src/app/shared/interfaces/Estoque';


@Injectable()
export class EstoqueService {

    constructor(private http: HttpClient) { }

    buscarEstoques(filtro: FiltroBarraPesquisa, pagina : number): Observable<Paginacao<Estoque>> {
        return this.http.post<Paginacao<Estoque>>(environment.URL_BASE + "WS/Estoque/Filtrar?page=" + pagina, filtro)
    }

    reintegrarEstoque(id : string) {
        return this.http.put<string>(environment.URL_BASE + "WS/Estoque/" + id, {}); 
    }

}
