import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Paginacao } from 'src/app/shared/interfaces/Paginacao';
import { Importacao } from 'src/app/shared/interfaces/Importacao';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { FiltroDto } from 'src/app/shared/interfaces/dto/FiltroDto';


@Injectable()
export class ImportacaoService {

    constructor(private http: HttpClient) { }

    buscarImportacoes(filtro: FiltroDto, pagina : number): Observable<Paginacao<Importacao>> {
        return this.http.post<Paginacao<Importacao>>(environment.URL_BASE + "WS/Importacao/Filtrar?page=" + pagina, filtro);
        //+ '&sortField=dataInclusao&sortDirection=ASC'
    }

    buscarImportacao(id : number): Observable<Importacao> {
        return this.http.get<Importacao>(environment.URL_BASE + "WS/Importacao/" + id  , {});
    }

    saveOrUpdate(Importacao:Importacao): Observable<Importacao> {
        return this.http.post<Importacao>(environment.URL_BASE + "WS/Importacao", Importacao);
    }

}
