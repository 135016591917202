import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Paginacao } from 'src/app/shared/interfaces/Paginacao';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Configuracao } from 'src/app/shared/interfaces/Configuracao';
import { FiltroBarraPesquisa } from 'src/app/shared/interfaces/FiltroBarraPesquisa';


@Injectable()
export class ConfiguracaoService {

    constructor(private http: HttpClient) { }

    buscarConfiguracoes(filtro: FiltroBarraPesquisa, pagina : number): Observable<Paginacao<Configuracao>> {
        return this.http.post<Paginacao<Configuracao>>(environment.URL_BASE + "WS/Configuracoes/Filtrar", filtro)
    }

    saveOrUpdate(configuracao: Configuracao): Observable<Configuracao> {
        return this.http.post<Configuracao>(environment.URL_BASE + "WS/Configuracoes", configuracao);
    }

}
