import { Component, OnInit } from "@angular/core";
import { Column } from "src/app/shared/interfaces/Column";
import { Subscription } from "rxjs";
import { Paginacao } from "src/app/shared/interfaces/Paginacao";
import { Router } from "@angular/router";
import { PedidoService } from "../services/pedido.service";
import { Pedido } from "src/app/shared/interfaces/Pedido";
import { FiltroBarraPesquisa } from "src/app/shared/interfaces/FiltroBarraPesquisa";
import { SelectItem } from "primeng/api";
import { ModalService } from "src/app/_modal";

@Component({
  templateUrl: "./pedidos.component.html",
  styleUrls: ["./pedidos.styl"],
  providers: [PedidoService],
})
export class PedidosComponent implements OnInit {
  colunas: Column[];
  pedidosPagina: Paginacao<Pedido> = {};
  pedidos$: Subscription;
  filtro: FiltroBarraPesquisa = {};
  filtrarErro: number = 0;
  comErro: SelectItem[];
  idSalvar: string;

  constructor(private pedidoService: PedidoService, private modalService: ModalService, private router: Router) {
    this.comErro = [
      { label: "Sim", value: 1 },
      { label: "Não", value: 0 },
    ];
  }

  ngOnInit() {
    this.colunas = [
      {
        field: "pedido",
        header: "PEDIDO",
        format: this.formatarCodigoPedido,
      },
      {
        field: "status",
        header: "TENTATIVAS DE INTEGRAR",
        format: this.formatarCodigoStatus,
      },
      {
        field: "status",
        header: "MENSAGEM",
        format: this.formatarMensagemStatus,
      },
    ];
    this.buscarPedidos(this.filtro, 0);
  }

  private formatarCodigoPedido(pedido: Pedido): string {
    return pedido.data.idOrigem;
  }
  private formatarCodigoStatus(pedido: Pedido): string {
    return pedido.status;
  }
  private formatarMensagemStatus(pedido: Pedido): string {
    if (pedido.statusMsg != null) {
      return pedido.statusMsg.substr(0, 5) === "java." || pedido.statusMsg.substr(0, 3) === "br."
        ? pedido.statusMsg.split("at", 1)[0]
        : pedido.statusMsg;
    }

    return null;
  }

  loadDataOnScroll(event) {
    this.buscarPedidos(this.filtro, event.first / event.rows);
  }

  reintegrarPedido(pedido: Pedido) {
    this.pedidoService.reintegrarPedido(pedido.id).subscribe((response) => {
      this.ngOnInit();
    });
    setTimeout(() => {
      this.ngOnInit();
    }, 1000);
  }


  removerPedido(pedido: Pedido) {
    this.pedidoService.removerPedido(pedido.id).subscribe((response) => {
      this.ngOnInit();
    });
    setTimeout(() => {
      this.ngOnInit();
    }, 1000);
  }

  buscar() {
    this.buscarPedidos(this.filtro, 0);
  }

  public buscarPedidos = (filtro: FiltroBarraPesquisa, pagina: number) => {
    this.pedidoService.buscarPedidos(filtro, pagina).subscribe((response) => {
      this.pedidosPagina = response;
    });
  };

  openModal(id: string, pedidoId: string) {
    this.modalService.open(id);
    this.idSalvar = pedidoId;
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }
  salvarIbge(ibge: string) {
    this.closeModal("custom-modal-1");
    this.pedidoService.salvarIbge(this.idSalvar, ibge).subscribe((response) => {
      this.ngOnInit();
    });
  }
}
