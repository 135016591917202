
export class Status {

    id? : string;
    seRodando? : string;
    dataUltimoInicio? : Date;
    dataProximoInicio?: Date;
    minutosEntreExecucao?: number;
    tipoIntegracao?: string;
    seSchedduled?: string;
    
}