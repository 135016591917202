import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { MessageService } from 'primeng/api';
import { NgxMaskModule } from 'ngx-mask'

import { SistemaComponent } from './sistema.component';
import { CoreModule } from '../core/core.module';
import { ImagePreloadDirective } from '../shared/directives/ImagePreload.directive';
import { SideBarModule } from '../core/side-bar/side-bar.module';
import { HomeComponent } from './home/home.component';
import { ResumoComponent } from './resumo/resumo.component';
import { HeaderModule } from '../core/header/header.module';
import { ProdutosComponent } from './produtos/produtos.component';
import { DropdownModule } from 'primeng/dropdown';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { UsuarioComponent } from './usuario/usuario.component';
import { PedidosComponent } from './pedidos/pedidos.component';
import { ButtonModule } from 'primeng/button';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ConfiguracoesComponent } from './configuracoes/configuracoes.component';
import { StatusComponent } from './status/status.component';
import { ModalModule } from 'src/app/_modal';
import { ImportacoesComponent } from './importacoes/importacoes.component';
import { EstoquesComponent } from './estoques/estoques.component';
import { ClientesComponent } from './clientes/clientes.component';


@NgModule({
    declarations: [
        SistemaComponent,
        ImagePreloadDirective,
        HomeComponent,
        ResumoComponent,
        ProdutosComponent,
        UsuariosComponent,
        UsuarioComponent,
        PedidosComponent,
        ConfiguracoesComponent,
        StatusComponent,
        ImportacoesComponent,
        EstoquesComponent,
        ClientesComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        FormsModule,
        MessagesModule,
        MessageModule,
        ToastModule,
        SideBarModule,
        HeaderModule,
        TableModule,
        DropdownModule,
        NgxMaskModule.forRoot(),
        ButtonModule,
        SelectButtonModule,
        ModalModule,
    ],
    providers: [
        MessageService
    ]
})
export class SistemaModule { }
