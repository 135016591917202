import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';

import { SelectItem } from 'primeng/api';

import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { FiltroBarraPesquisa } from 'src/app/shared/interfaces/FiltroBarraPesquisa';
import { UsuarioService } from '../../../sistema/services/usuario.service';
import { Arquivo } from 'src/app/shared/interfaces/Arquivo';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Importacao } from 'src/app/shared/interfaces/Importacao';
import { TipoImportacao } from 'src/app/shared/enums/TipoImportacao';
import { Router } from '@angular/router';


const TAMANHO_MAX_ARQUIVO = 20000;
const TIPO_ACEITO = "application";

@Component({
    selector: 'dc-importar-arquivo',
    templateUrl: './importar-arquivo.component.html',
    styleUrls: ['./importar-arquivo.component.styl'],
    providers: [
        UsuarioService
    ]
})
export class ImportarArquivoComponent implements OnInit, OnDestroy {

    importacao: Importacao = {};
    arquivo: Arquivo = {};
    modelArquivo: String = "";
    tiposImportacao: SelectItem[];
    tipoSelecionado: TipoImportacao;

    @ViewChild('popupImportarArquivo', { static: true })
    popupImportarArquivo: ElementRef<HTMLDivElement>;

    @ViewChild('uploadArquivo', { static: true })
    uploadInput: ElementRef<HTMLInputElement>;

    constructor(
        private usuarioService: UsuarioService,
        private messageService: MessageService,
        private http: HttpClient,
        private router: Router
    ) { 
        this.tiposImportacao = [
			{label:'Condições de Pagamento', value:TipoImportacao.CONDICOES_DE_PAGAMENTO},
            {label:'Tabelas de Preços', value:TipoImportacao.TABELA_DE_PRECOS},
            {label:'Comissões', value:TipoImportacao.COMISSOES},
            {label:'IPI', value:TipoImportacao.IPI}
        ];
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        // this.representantes$.unsubscribe()
    }

    controlarPopupImportarArquivo() {
        const popup: HTMLDivElement = this.popupImportarArquivo.nativeElement;

        if (popup.style.display === 'none') {
            popup.style.display = 'flex';
        } else {
            popup.style.display = 'none';
        }
    }

    enviarArquivo() {
        this.http.post<Arquivo>(environment.URL_BASE + "WS/Importacao", this.importacao,{observe:'response'}).subscribe(
            response => {
                this.controlarPopupImportarArquivo();
                if(response.status == 201){
                    this.messageService.add({
                        key: 'toast',
                        severity: 'success',
                        summary: 'Sucesso',
                        detail: 'O arquivo foi enviado! Você pode acompanhar o processamento na tela de importações!'
                    });
                    setTimeout(() => window.location.reload(), 1000);
                }
            },
            error => {
                this.messageService.add({
                    key: 'toast',
                    severity: 'error',
                    summary: 'Erro',
                    detail: 'Houve um erro ao importar o arquivo!'
                });
                setTimeout(() => window.location.reload(), 1000);
            }
        );
    }

    abrirUpload() {
        this.uploadInput.nativeElement.click();
    }

    converterArquivo(event) {
        const component = this;
        const arquivo = event.target.files[0];
        const splitTipoArquivo = arquivo.type.split("/");

        if (splitTipoArquivo[0] != TIPO_ACEITO) {
            component.messageService.add({
                key: 'toast',
                severity: 'error',
                summary: 'Erro',
                detail: `Arquivo: ${arquivo.name} não é um formato válido`
            });
            return;
        }

        const tamanhoArquivo = (arquivo.size / 1024);

        if (tamanhoArquivo > TAMANHO_MAX_ARQUIVO) {
            component.messageService.add({
                key: 'toast',
                severity: 'error',
                summary: 'Erro',
                detail: `Arquivo: ${arquivo.name} é maior que 20MB`
            });
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(arquivo);

        reader.onload = (arquivoConvertido: any) => {
            const dados = arquivoConvertido.target.result.split("base64,");
            this.importacao = {
                tipoImportacao : this.tipoSelecionado,
                nomeArquivo: arquivo.name,
                bytes: dados[1],
                tamanho: arquivo.size,
            }
            this.controlarPopupImportarArquivo();
        }

        reader.onerror = (error) => {
            component.messageService.add({
                key: 'toast',
                severity: 'error',
                summary: 'Erro',
                detail: `Não foi possivel ler o arquivo.`
            });
            console.log('Error: ', error);
        };
    }    

}
