import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Usuario } from 'src/app/shared/interfaces/Usuario';
import { UsuarioService } from '../../sistema/services/usuario.service';
import {TokenService} from '../token/token.service'
import { Router } from '@angular/router';

@Component({
    selector: 'dc-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.styl']
})
export class SideBarComponent implements OnInit {

    usuario: Usuario;

    @ViewChild('menuLateral', { static: true })
    menuLateral: ElementRef;

    @ViewChild('fundoPreto', { static: true })
    fundoPreto: ElementRef<HTMLDivElement>;

    constructor( 
        private usuarioService: UsuarioService, 
        private tokenService: TokenService, 
        private router: Router) {
    }

    ngOnInit() {
         this.usuario = this.usuarioService.getUsuarioLogado();
         this.usuarioService.buscarUsuario(this.usuario.id).subscribe(
            response => {
                this.usuario = response;
            }
        ); 
    }

    logout() {
        this.tokenService.removeToken();
        this.usuarioService.removeUsuarioLogado();
        this.router.navigate(['/login']);
    }

    abrirFecharMenu() {
        const menu = this.menuLateral.nativeElement;
        const fundo = this.fundoPreto.nativeElement;
        if (menu.classList.contains('ativo')) {
            menu.classList.remove('ativo');
            fundo.classList.remove('ativo');
        } else {
            menu.classList.add('ativo');
            fundo.classList.add('ativo');
        }
    }

    fecharMenu() {
        const menu = this.menuLateral.nativeElement;
        const fundo = this.fundoPreto.nativeElement;
        if (menu.classList.contains('ativo')) {
            menu.classList.remove('ativo');
            fundo.classList.remove('ativo');
        }
    }

}
