import { Component, OnInit } from '@angular/core';
import { Column } from 'src/app/shared/interfaces/Column';
import { Subscription } from 'rxjs';
import { Paginacao } from 'src/app/shared/interfaces/Paginacao';
import { Configuracao } from 'src/app/shared/interfaces/Configuracao';
import { ConfiguracaoService } from '../services/configuracao.service';
import { Router } from '@angular/router';
import { FiltroBarraPesquisa } from 'src/app/shared/interfaces/FiltroBarraPesquisa';
import { SelectItem } from 'primeng/api';
import { ModalService } from 'src/app/_modal';

@Component({
    templateUrl: './configuracoes.component.html',
    styleUrls: ['./configuracoes.styl'],
    providers: [
        ConfiguracaoService
    ]
})
export class ConfiguracoesComponent implements OnInit {

    colunas: Column[];
    configuracaoPagina: Paginacao<Configuracao> = {};
    configuracao$: Subscription;
    filtro: FiltroBarraPesquisa = {};
    tipoStatus: SelectItem[];
    configuracaoSalvar: Configuracao = new Configuracao();

    constructor(
        private configuracaoService: ConfiguracaoService,
        private modalService: ModalService,
        private router: Router
    ) {}

    ngOnInit() {
        this.colunas = [
            {
                field: 'codigo',
                header: 'CODIGO',
                format: this.formatarCodigoConfiguracao
            },
            {
                field: 'valor',
                header: 'VALOR',
                format: this.formatarValor
            },
        ];
        this.buscarConfiguracoes(this.filtro,0);
    }

    private formatarCodigoConfiguracao(configuracao: Configuracao): string {
        return configuracao.id;
    }

    private formatarValor(configuracao: Configuracao): string {
        return configuracao.value;
    }

    loadDataOnScroll(event) {
        this.buscarConfiguracoes(this.filtro, event.first / event.rows);
    }

    buscar() {
        this.buscarConfiguracoes(this.filtro, 0);
    }

    openModal(id: string, configuracaoEdicao: Configuracao) {
        this.modalService.open(id);
        this.configuracaoSalvar = configuracaoEdicao;
    }

    closeModal(id: string) {
        this.modalService.close(id);
    }

    salvarOuEditarConfiguracao(configuracaoEdicao: Configuracao) {
        this.closeModal('custom-modal-1');
        this.configuracaoSalvar = configuracaoEdicao;
        this.configuracaoService.saveOrUpdate(this.configuracaoSalvar).subscribe(
            response => {
                this.ngOnInit();
            }
        );
    }

    public buscarConfiguracoes = (filtro: FiltroBarraPesquisa, pagina : number) => {
        this.configuracaoService.buscarConfiguracoes(filtro, pagina).subscribe(
            response => {
                this.configuracaoPagina = response;
            }
        );
    }

}
