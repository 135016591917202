import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Paginacao } from 'src/app/shared/interfaces/Paginacao';
import { Produto } from 'src/app/shared/interfaces/Produto';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { FiltroBarraPesquisa } from 'src/app/shared/interfaces/FiltroBarraPesquisa';


@Injectable()
export class ProdutoService {

    constructor(private http: HttpClient) { }

    buscarProdutos(filtro: FiltroBarraPesquisa, pagina : number): Observable<Paginacao<Produto>> {
        return this.http.post<Paginacao<Produto>>(environment.URL_BASE + "WS/Produto/Filtrar?page=" + pagina, filtro);
    }

    reintegrarProduto(id : string) {
        return this.http.put<string>(environment.URL_BASE + "WS/Produto/" + id, {});
    }

}
