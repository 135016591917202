import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Paginacao } from "src/app/shared/interfaces/Paginacao";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { FiltroBarraPesquisa } from "src/app/shared/interfaces/FiltroBarraPesquisa";
import { Cliente } from "src/app/shared/interfaces/Cliente";

@Injectable()
export class ClienteService {
  constructor(private http: HttpClient) {}

  buscarClientes(filtro: FiltroBarraPesquisa, pagina: number): Observable<Paginacao<Cliente>> {
    return this.http.post<Paginacao<Cliente>>(environment.URL_BASE + "WS/Cliente/Filtrar?page=" + pagina, filtro);
  }

  reintegrarCliente(id: string) {
    return this.http.put<string>(environment.URL_BASE + "WS/Cliente/" + id, {});
  }

  removerCliente(id: string) {
    return this.http.delete<string>(environment.URL_BASE + "WS/Cliente/" + id, {});
  }

  salvarUf(id: string, uf: string) {
    return this.http.put<string>(environment.URL_BASE + "WS/Cliente/" + id + "/" + uf, {});
  }
}
