import { Component, OnInit, Input } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { FiltroBarraPesquisa } from 'src/app/shared/interfaces/FiltroBarraPesquisa';
import { TipoFiltro } from 'src/app/shared/enums/tipoFiltro';
import {Subject} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/internal/operators";
import {DropdownModule} from 'primeng/dropdown';

@Component({
	selector: 'dc-barra-pesquisa',
	templateUrl: './barra-pesquisa.component.html',
	styleUrls: ['./barra-pesquisa.component.styl']
})
export class BarraPesquisaComponent implements OnInit {

	filtro: FiltroBarraPesquisa;
	txtQueryChanged: Subject<string> = new Subject<string>();
	txtBuscaLivre: string; // bind this to input with ngModel
	@Input() onChange: (filtro: FiltroBarraPesquisa, pagina) => {};

	constructor() {
		this.txtQueryChanged
		.pipe(debounceTime(700), distinctUntilChanged())
		.subscribe(model => {
			this.txtBuscaLivre = model;
			this.filtro.buscaLivre = this.txtBuscaLivre;
			this.onChange(this.filtro, 0);
		});
	}

	ngOnInit() {
		this.filtro = {};
	}

	onFieldChange(query:string){
		this.txtQueryChanged.next(query);
	}

}
