import { Component, OnInit } from "@angular/core";
import { Column } from "src/app/shared/interfaces/Column";
import { Subscription } from "rxjs";
import { Paginacao } from "src/app/shared/interfaces/Paginacao";
import { Router } from "@angular/router";
import { FiltroBarraPesquisa } from "src/app/shared/interfaces/FiltroBarraPesquisa";
import { SelectItem } from "primeng/api";
import { Estoque } from "src/app/shared/interfaces/Estoque";
import { EstoqueService } from "../services/estoque.service";

@Component({
  templateUrl: "./estoques.component.html",
  styleUrls: ["./estoques.styl"],
  providers: [EstoqueService],
})
export class EstoquesComponent implements OnInit {
  colunas: Column[];
  estoquesPagina: Paginacao<Estoque> = {};
  estoques$: Subscription;
  filtro: FiltroBarraPesquisa = {};
  filtrarErro: number = 0;
  comErro: SelectItem[];

  constructor(private estoqueService: EstoqueService, private router: Router) {
    this.comErro = [
      { label: "Sim", value: 1 },
      { label: "Não", value: 0 },
    ];
  }

  ngOnInit() {
    this.colunas = [
      {
        field: "produto",
        header: "PRODUTO",
        format: this.formatarCodigoProduto,
      },
      {
        field: "quantidade",
        header: "QUANTIDADE",
        format: this.formatarQuantidade,
      },
      {
        field: "tipo",
        header: "TIPO MOVIMENTAÇÃO",
        format: this.formatarTipoMovimentacao,
      },
      {
        field: "status",
        header: "TENTATIVAS DE INTEGRAR",
        format: this.formatarCodigoStatus,
      },
      {
        field: "status",
        header: "MENSAGEM",
        format: this.formatarMensagemStatus,
      },
    ];
    this.buscarEstoques(this.filtro, 0);
  }

  private formatarCodigoProduto(estoque: Estoque): string {
    return estoque.idProduto;
  }
  private formatarQuantidade(estoque: Estoque): number {
    return estoque.quantidade;
  }
  private formatarTipoMovimentacao(estoque: Estoque): string {
    if (estoque.tipoMovimentacao == 0) {
      return "Entrada";
    } else {
      return "Saída";
    }
  }
  private formatarCodigoStatus(estoque: Estoque): string {
    return estoque.status;
  }
  private formatarMensagemStatus(estoque: Estoque): string {
    if (estoque.statusMsg != null) {
      return estoque.statusMsg.substr(0, 5) === "java." || estoque.statusMsg.substr(0, 3) === "br."
        ? estoque.statusMsg.split("at", 1)[0]
        : estoque.statusMsg;
    } else {
      return null;
    }
  }

  loadDataOnScroll(event) {
    this.buscarEstoques(this.filtro, event.first / event.rows);
  }

  reintegrarEstoque(estoque: Estoque) {
    this.estoqueService.reintegrarEstoque(estoque.id).subscribe((response) => {
      this.ngOnInit();
    });
    setTimeout(() => {
      this.ngOnInit();
    }, 1000);
  }

  buscar() {
    this.buscarEstoques(this.filtro, 0);
  }

  public buscarEstoques = (filtro: FiltroBarraPesquisa, pagina: number) => {
    this.estoqueService.buscarEstoques(filtro, pagina).subscribe((response) => {
      this.estoquesPagina = response;
    });
  };
}
