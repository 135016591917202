import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Usuario } from 'src/app/shared/interfaces/Usuario';
import { Arquivo } from 'src/app/shared/interfaces/Arquivo';
import { MessageService } from 'primeng/api';
import { UsuarioService } from '../services/usuario.service';

const TAMANHO_MAX_ARQUIVO = 2000;
const TIPO_ACEITO = 'image';

@Component({
    templateUrl: './usuario.component.html',
    styleUrls: ['./usuario.styl'],
    providers: [
        UsuarioService
    ]
})
export class UsuarioComponent implements OnInit {

    idusuario: number;
    usuario: Usuario = new Usuario();
    arquivo: Arquivo;

    @ViewChild('upload', { static: true })
    uploadInput: ElementRef<HTMLInputElement>;

    constructor(
        private activatedRoute: ActivatedRoute,
        private usuarioService: UsuarioService,
        private router: Router,
        private messageService: MessageService
    ) {
        this.idusuario = this.activatedRoute.snapshot.params.id;
    }

    ngOnInit() {
        if (this.idusuario) {
            this.buscarusuario();
        } else {
            this.usuario = { endereco: {} };
        }
    }

    voltarusuarios() {
        this.router.navigate(['usuarios'])
    }

    abrirUpload() {
        this.uploadInput.nativeElement.click();
    }

    converterArquivo(event) {
        const component = this;
        const arquivo = event.target.files[0];

        const splitTipoArquivo = arquivo.type.split("/");

        if (splitTipoArquivo[0] != TIPO_ACEITO) {
            component.messageService.add({
                key: 'toast',
                severity: 'error',
                summary: 'Erro',
                detail: `Arquivo: ${arquivo.name} não é um formato válido`
            });
            return;
        }

        const tamanhoArquivo = (arquivo.size / 1024);

        if (tamanhoArquivo > TAMANHO_MAX_ARQUIVO) {
            component.messageService.add({
                key: 'toast',
                severity: 'error',
                summary: 'Erro',
                detail: `Arquivo: ${arquivo.name} é maior que 2MB`
            });
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(arquivo);

        reader.onload = (arquivoConvertido: any) => {
            const dados = arquivoConvertido.target.result.split("base64,");
            this.usuario.foto = {
                nome: arquivo.name,
                bytes: dados[1],
                tamanho: arquivo.size,
            }
        }

        reader.onerror = (error) => {
            component.messageService.add({
                key: 'toast',
                severity: 'error',
                summary: 'Erro',
                detail: `Não foi possivel ler a imagem.`
            });
            console.log('Error: ', error);
        };
    }

    salvarOuEditarusuario() {
        if (this.validarCamposusuario()) {
            return;
        }
        this.usuarioService.saveOrUpdate(this.usuario).subscribe(
            response => {
                this.voltarusuarios();
            }
        );
    }

    private buscarusuario() {
        this.usuarioService.buscarUsuario(this.idusuario).subscribe(
            response => {
                this.usuario = response;
                this.usuario.senha = null;
                this.usuario.confirmacaoSenha = null;
                if ( ! this.usuario.foto ) {
                    this.usuario.foto = null;
                }
            }
        );
    }

    private validarCamposusuario(): boolean {
        const usuario: Usuario = this.usuario;
        let erro = false;

        if (!usuario.email || usuario.email === '') {
            this.messageService.add({
                key: 'toast',
                severity: 'warn',
                summary: 'Por favor',
                detail: `Preencha o campo E-mail`
            });
            erro = true;
        }
        if (!usuario.nome || usuario.nome === '') {
            this.messageService.add({
                key: 'toast',
                severity: 'warn',
                summary: 'Por favor',
                detail: `Preencha o campo Nome`
            });
            erro = true;
        }

        if ( usuario.senha !== usuario.confirmacaoSenha ) {
            this.messageService.add({
                key: 'toast',
                severity: 'warn',
                summary: 'Por favor',
                detail: `Senha e confirmação não são iguais`
            });
            erro = true;
        }        

        return erro;
    }

}
