import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Paginacao } from 'src/app/shared/interfaces/Paginacao';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Status } from 'src/app/shared/interfaces/Status';
import { FiltroBarraPesquisa } from 'src/app/shared/interfaces/FiltroBarraPesquisa';


@Injectable()
export class StatusService {

    constructor(private http: HttpClient) { }

    buscarStatus(filtro: FiltroBarraPesquisa, pagina : number): Observable<Paginacao<Status>> {
        return this.http.post<Paginacao<Status>>(environment.URL_BASE + "WS/Status/Filtrar?page=" + pagina, filtro)
    }

    saveOrUpdate(status: Status): Observable<Status> {
        return this.http.post<Status>(environment.URL_BASE + "WS/Status", status);
    }

}
