import { BaseEntity } from './BaseEntity';
import { Endereco } from './Endereco';
import { Arquivo } from './Arquivo';

export class Pessoa extends BaseEntity {
    nome?: string;
    endereco?: Endereco;
    telefone?: string;
    celular?: string;
    email?: string;
    documento?: string;
    foto?: Arquivo;
    cpfCnpj?: string;
}
