import { Component, OnInit } from '@angular/core';
import { Column } from 'src/app/shared/interfaces/Column';
import { Subscription } from 'rxjs';
import { Paginacao } from 'src/app/shared/interfaces/Paginacao';
import { Importacao } from 'src/app/shared/interfaces/Importacao';
import { ImportacaoService } from '../services/importacao.service';
import { FiltroDto } from 'src/app/shared/interfaces/dto/FiltroDto';
import {Subject} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/internal/operators";
import { SelectItem } from 'primeng/api';
import { ImportarArquivoComponent } from '../../core/header/importar-arquivo/importar-arquivo.component';

@Component({
    templateUrl: './importacoes.component.html',
    styleUrls: ['./importacoes.styl'],
    providers: [
        ImportacaoService
    ]
})
export class ImportacoesComponent implements OnInit {

    colunas: Column[];
    importacoesPagina: Paginacao<Importacao> = {};
    importacoes$: Subscription;
    situacoes: SelectItem[];
    filtro: FiltroDto = {};
    situacaoChanged: Subject<string> = new Subject<string>();
    situacao: string;

    constructor(
        private importacaoService: ImportacaoService
    ) { 
        this.situacoes = [
			{label:'TODAS', value:null},
			{label:'PENDENTE', value:'PENDENTE'},
			{label:'FINALIZADA', value:'FINALIZADA'},
			{label:'ERRO', value:'ERRO'},
            {label:'PROCESSANDO', value:'PROCESSANDO'}
        ];

        this.situacaoChanged
		.pipe(debounceTime(700), distinctUntilChanged())
		.subscribe(model => {
			this.situacao = model;
			this.filtro.statusImportacao = this.situacao;
			this.buscarImportacoes(this.filtro, 0);
		});
    }

    ngOnInit() {
        this.colunas = [
            {
                field: 'id',
                header: 'CODIGO'
            },
            {
                field: 'tipoImportacao',
                header: 'TIPO',
                format: this.formatarTipo
            },
            {
                field: 'mensagemErro',
                header: 'MENSAGEM DE ERRO',
            },
            
        ];
        this.buscarImportacoes(this.filtro,0);
    }

    loadDataOnScroll(event) {
        this.buscarImportacoes(this.filtro, event.first / event.rows);
    }

    onFieldChange(query:string){
		this.situacaoChanged.next(query);
	}

    private formatarTipo(importacao: Importacao): string {
        return importacao.tipoImportacao.toString();
    }


    public buscarImportacoes = (filtro: FiltroDto, pagina : number) => {
        this.importacaoService.buscarImportacoes(filtro, pagina).subscribe(
            response => {
                this.importacoesPagina = response;
            }
        );
    }

}
