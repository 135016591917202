import { Component, OnInit } from "@angular/core";
import { Column } from "src/app/shared/interfaces/Column";
import { Subscription } from "rxjs";
import { Paginacao } from "src/app/shared/interfaces/Paginacao";
import { Router } from "@angular/router";
import { FiltroBarraPesquisa } from "src/app/shared/interfaces/FiltroBarraPesquisa";
import { SelectItem } from "primeng/api";
import { ClienteService } from "../services/cliente.service";
import { Cliente } from "src/app/shared/interfaces/Cliente";
import { ModalService } from "src/app/_modal";

@Component({
  templateUrl: "./clientes.component.html",
  styleUrls: ["./clientes.styl"],
  providers: [ClienteService],
})
export class ClientesComponent implements OnInit {
  colunas: Column[];
  clientesPagina: Paginacao<Cliente> = {};
  clientes$: Subscription;
  filtro: FiltroBarraPesquisa = {};
  filtrarErro: number = 0;
  comErro: SelectItem[];
  idSalvar: string;

  constructor(private clientesService: ClienteService, private modalService: ModalService, private router: Router) {
    this.comErro = [
      { label: "Sim", value: 1 },
      { label: "Não", value: 0 },
    ];
  }

  ngOnInit() {
    this.colunas = [
      {
        field: "codigoCliente",
        header: "CLIENTE",
        format: this.formatarCodigoCliente,
      },
      {
        field: "status",
        header: "TENTATIVAS DE INTEGRAR",
        format: this.formatarStatus,
      },
      {
        field: "statusMsg",
        header: "MENSAGEM",
        format: this.formatarMensagem,
      },
    ];
    this.buscarClientes(this.filtro, 0);
  }

  private formatarCodigoCliente(cliente: Cliente): string {
    return cliente.codigoCliente;
  }

  private formatarStatus(cliente: Cliente): string {
    return cliente.status;
  }

  private formatarMensagem(cliente: Cliente): string {
    if (cliente.statusMsg != null) {
      if (cliente.statusMsg.substr(0, 5) === "java.") {
        return cliente.statusMsg.split(":", 2)[1].split("at")[0];
      } else {
        if (cliente.statusMsg.substr(0, 3) === "br.") {
          if (cliente.statusMsg.includes("existe em um site associado.")) {
            return "Não foi possível salvar o cliente: Já existe um cliente com o mesmo email cadastrado.";
          } else {
            if (cliente.statusMsg.includes("u00e1lido")) {
              return "O email cadastrado não é válido.";
            } else {
              if (cliente.statusMsg.includes("Uma conta com o mesmo CPF")) {
                return "Uma conta com o mesmo CPF/CNPJ cadastrado já existe.";
              }
              return cliente.statusMsg.split("DemacodeException:", 2)[1].split("\n")[0];
            }
          }
        } else {
          return cliente.statusMsg;
        }
      }
    }

    return null;
  }

  loadDataOnScroll(event) {
    this.buscarClientes(this.filtro, event.first / event.rows);
  }

  reintegrarCliente(cliente: Cliente) {
    this.clientesService.reintegrarCliente(cliente.id).subscribe((response) => {
      this.ngOnInit();
    });
    setTimeout(() => {
      this.ngOnInit();
    }, 1000);
  }

  limpar() {

    this.clientesPagina.content.map((cli)=>{
      console.log(cli.id);
      this.clientesService.removerCliente(cli.id).subscribe((response) => {});
    })
    setTimeout(() => {
      this.ngOnInit();
    }, 1000);
    
  }

  removerCliente(cliente: Cliente) {
    this.clientesService.removerCliente(cliente.id).subscribe((response) => {
      this.ngOnInit();
    });
    setTimeout(() => {
      this.ngOnInit();
    }, 1000);
  }  

  buscar() {
    this.buscarClientes(this.filtro, 0);
  }

  public buscarClientes = (filtro: FiltroBarraPesquisa, pagina: number) => {
    this.clientesService.buscarClientes(filtro, pagina).subscribe((response) => {
      this.clientesPagina = response;
    });
  };

  openModal(id: string, clienteId: string) {
    this.modalService.open(id);
    this.idSalvar = clienteId;
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }
  salvarUf(uf: string) {
    this.closeModal("custom-modal-1");
    this.clientesService.salvarUf(this.idSalvar, uf).subscribe((response) => {
      this.ngOnInit();
    });
  }
}
