import { Component, OnInit } from "@angular/core";
import { Column } from "src/app/shared/interfaces/Column";
import { Subscription } from "rxjs";
import { Paginacao } from "src/app/shared/interfaces/Paginacao";
import { Status } from "src/app/shared/interfaces/Status";
import { StatusService } from "../services/status.service";
import { Router } from "@angular/router";
import { FiltroBarraPesquisa } from "src/app/shared/interfaces/FiltroBarraPesquisa";
import { SelectItem } from "primeng/api";
import { ModalService } from "src/app/_modal";
import { TipoFiltro } from "src/app/shared/enums/tipoFiltro";

@Component({
  templateUrl: "./status.component.html",
  styleUrls: ["./status.styl"],
  providers: [StatusService],
})
export class StatusComponent implements OnInit {
  colunas: Column[];
  statusPagina: Paginacao<Status> = {};
  status$: Subscription;
  filtro: FiltroBarraPesquisa = {};
  tipoStatus: SelectItem[];
  seAgendado: SelectItem[];
  minutosEntreExecucao: number;
  statusSalvar: Status = new Status();

  constructor(private statusService: StatusService, private modalService: ModalService, private router: Router) {
    this.tipoStatus = [
      { label: "TODOS", value: null },
      { label: "PEDIDOS", value: "O" },
      { label: "PRODUTOS", value: "P" },
      { label: "ESTOQUE", value: "E" },
      { label: "CLIENTE", value: "C" },
      { label: "TRANSAÇÕES", value: "G" },
      { label: "STATUS PEDIDO", value: "S" },
      { label: "IMPORTAÇÕES", value: "I" },
    ];
    this.seAgendado = [
      { label: "SIM", value: "S" },
      { label: "NÃO", value: "N" },
    ];
  }

  ngOnInit() {
    this.colunas = [
      {
        field: "tipoIntegracao",
        header: "TIPO INTEGRAÇÃO",
        format: this.formatarTipoIntegracao,
      },
      {
        field: "seRodando",
        header: "SE RODANDO",
        format: this.formatarSeRodando,
      },
      {
        field: "minutosEntreExecucao",
        header: "MINUTOS ENTRE EXECUÇÃO",
        format: this.formatarMinutosEntreExecucao,
      },
      {
        field: "seAgendado",
        header: "SE AGENDADO",
        format: this.formatarSeAgendado,
      },
    ];
    this.buscarStatus(this.filtro, 0);
  }

  private formatarSeRodando(status: Status): string {
    return status.seRodando == "S" ? "Sim" : "Não";
  }

  private formatarMinutosEntreExecucao(status: Status): number {
    return status.minutosEntreExecucao;
  }

  private formatarTipoIntegracao(status: Status): string {
    var tipo = "";

    switch (status.tipoIntegracao) {
      case "O": {
        tipo = "PEDIDOS";
        break;
      }
      case "P": {
        tipo = "PRODUTOS";
        break;
      }
      case "E": {
        tipo = "ESTOQUE";
        break;
      }
      case "C": {
        tipo = "CLIENTE";
        break;
      }
      case "G": {
        tipo = "TRANSAÇÕES";
        break;
      }
      case "S": {
        tipo = "STATUS PEDIDO";
        break;
      }
      case "I": {
        tipo = "IMPORTAÇÕES";
        break;
      }
      default: {
        tipo = "";
        break;
      }
    }

    return tipo;
  }

  private formatarSeAgendado(status: Status): string {
    return status.seSchedduled == "S" ? "SIM" : "NÃO";
  }

  loadDataOnScroll(event) {
    this.buscarStatus(this.filtro, event.first / event.rows);
  }

  buscar() {
    this.buscarStatus(this.filtro, 0);
  }

  openModal(id: string, statusEdicao: Status) {
    this.modalService.open(id);
    this.statusSalvar = statusEdicao;
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  salvarOuEditarStatus(statusEdicao: Status) {
    this.closeModal("custom-modal-1");
    this.statusSalvar = statusEdicao;
    this.statusService.saveOrUpdate(this.statusSalvar).subscribe((response) => {
      this.ngOnInit();
    });
  }

  public buscarStatus = (filtro: FiltroBarraPesquisa, pagina: number) => {
    this.statusService.buscarStatus(filtro, pagina).subscribe((response) => {
      this.statusPagina = response;
    });
  };
}
